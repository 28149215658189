import React from "react";

const ContactPage = () => {
  const cat = `
  /\\_/\\
 ( o.o )
  > ^ <
 `
  return (
    <>
      email: saturdayagape@gmail.com
      <pre>{cat}</pre>
    </>
  );
};

export default ContactPage;
